<template>
  <div>
    <b-card
      v-if="barcodeData !== null"
      :title="
        `Upload Report for ${barcodeData.corBarcode}/${barcodeData.stBarcode}`
      "
    >
      <main>
        <b-card
          class="mx-1"
        >
          <b-card-title>
            Starter nutrition plan
            <b-badge
              v-if="typeof barcodeData.reports !== 'undefined' && typeof barcodeData.reports.starterNutritionPlan !== 'undefined'"
              pill
              variant="light-primary"
              class="ml-2"
            >Uploaded</b-badge>
          </b-card-title>
          <b-input-group>
            <b-form-file
              v-model="starterReportFile"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @input="(event) => inputValidation('starter', event)"
            />
            <b-input-group-append>
              <b-button
                v-if="typeof barcodeData.reports !== 'undefined' && typeof barcodeData.reports.starterNutritionPlan !== 'undefined'"
                variant="outline-primary"
                @click="handleViewPDF('starterNutritionPlan')"
              >
                View
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-text id="password-help-block">
            File name format: NH-XXXXX-COR_starterNutritionPlan.pdf/NH-XXXXXXX-ST_starterNutritionPlan.pdf
          </b-form-text>
          <span
            v-if="valid.val && valid.index === 0"
            class="text-danger font-weight-bolder my-1"
          >{{ valid.message }}</span>
        </b-card>
        <b-card
          class="mx-1"
        >
          <b-card-title>
            Gut Microbiome Report
            <b-badge
              v-if="typeof barcodeData.reports !== 'undefined' && typeof barcodeData.reports.gutMicroBiomeReport !== 'undefined'"
              pill
              variant="light-primary"
              class="ml-2"
            >Uploaded</b-badge>
          </b-card-title>
          <b-input-group>
            <b-form-file
              v-model="microbiomeReportFile"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @input="(event) => inputValidation('microbiome', event)"
            />
            <b-input-group-append>
              <b-button
                v-if="typeof barcodeData.reports !== 'undefined' && typeof barcodeData.reports.gutMicrobiomeReport !== 'undefined'"
                variant="outline-primary"
                @click="handleViewPDF('gutMicrobiomeReport')"
              >
                View
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-text id="password-help-block">
            File name format: NH-XXXXX-COR_gutMicrobiomeReport.pdf/NH-XXXXXXX-ST_gutMicrobiomeReport.pdf
          </b-form-text>
          <span
            v-if="valid.val && valid.index === 1"
            class="text-danger font-weight-bolder my-1"
          >{{ valid.message }}</span>
        </b-card>
        <b-card
          class="mx-1"
        >
          <b-card-title>
            Gut Restorer Plan
            <b-badge
              v-if="typeof barcodeData.reports !== 'undefined' && typeof barcodeData.reports.gutRestorerPlan !== 'undefined'"
              pill
              variant="light-primary"
              class="ml-2"
            >Uploaded</b-badge>
          </b-card-title>
          <b-input-group>
            <b-form-file
              v-model="restorerReportFile"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @input="(event) => inputValidation('restorer', event)"
            />
            <b-input-group-append>
              <b-button
                v-if="typeof barcodeData.reports !== 'undefined' && typeof barcodeData.reports.gutRestorerPlan !== 'undefined'"
                variant="outline-primary"
                @click="handleViewPDF('gutRestorerPlan')"
              >
                View
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-text id="password-help-block">
            File name format: NH-XXXXX-COR_gutRestorerPlan.pdf/NH-XXXXXXX-ST_gutRestorerPlan.pdf
          </b-form-text>
          <span
            v-if="valid.val && valid.index === 2"
            class="text-danger font-weight-bolder my-1"
          >{{ valid.message }}</span>
        </b-card>
      </main>
      <div class="d-flex justify-content-end w-100">
        <b-button
          variant="outline-primary"
          @click="submit"
        >
          Submit
        </b-button>
      </div>
    </b-card>
    <b-modal
      id="modal-xl"
      ref="viewPdfRef"
      ok-only
      ok-title="Close"
      centered
      size="xl"
      :title="currentTitle"
      @hide="handleHide"
    >
      <b-card-text class="h-100 w-100">
        <div
          v-if="loading"
          class="d-flex w-100 align-items-center justify-content-center my-5"
        >
          <b-spinner label="Loading..." />
        </div>
        <object
          v-else
          :data="pdfSrc"
          style="width: 100%; height: 100%"
          type="application/pdf"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
export default {
  data() {
    return {
      // to keep track of uploading status of files
      currentTitle: null,
      pdfSrc: null,
      loading: 0,
      id: null,
      barcodeData: null,
      starterReportFile: null,
      microbiomeReportFile: null,
      restorerReportFile: null,
      valid: {
        val: false,
        index: null,
        message: 'Incorrect filename',
      },
      reports: {
      },
    }
  },
  beforeMount() {
    this.showLoading()
    this.id = this.$route.params.id
    this.$http
      .get(`/barcodes/${this.id}`)
      .then(({ data }) => (this.barcodeData = data))
      .catch(this.handleError).finally(this.closeLoading)
  },
  methods: {
    TitleCase(s) {
      return s
        .replace(/(^|[_-])([a-z])/g, (a, b, c) => c.toUpperCase())
        .replace(/([a-z])([A-Z])/g, (a, b, c) => `${b} ${c}`)
    },
    Capitalize(str) {
      return str.trim().replace(/^\w/, c => c.toUpperCase())
    },
    getBlob(link) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'GET',
          url: link,
          responseType: 'blob',
        }).then(resp => {
          resolve(window.URL.createObjectURL(resp.data))
        }).catch(err => {
          reject(err)
          this.handleError(err)
        })
          .finally(() => {
            this.loading = false
          })
      })
    },
    handleHide() {
      this.currentTitle = null
      this.pdfSrc = null
    },
    async handleViewPDF(reportType) {
      this.$refs.viewPdfRef.show()
      this.loading = true
      this.currentTitle = `${this.TitleCase(reportType)} Report`
      if (typeof this.reports[reportType] !== 'undefined') {
        this.pdfSrc = this.reports[reportType]
        this.loading = null
      } else {
        this.reports[reportType] = await this.getBlob(this.barcodeData.reports[reportType])
        this.pdfSrc = this.reports[reportType]
      }
      // fetch(this.barcodeData.reports[reportType])
      //   .then(resp => resp.blob())
      //   .then(blob => {
      //     this.pdfSrc = window.URL.createObjectURL(blob)
      //   })
      //   .catch(this.handleError)
      //   .finally(() => {
      //     this.loading = false
      //   })
    },
    inputValidation(type, file) {
      this.valid = {
        val: false,
        index: null,
        message: 'Incorrect filename',
      }
      const { name } = file
      // eslint-disable-next-line default-case
      switch (type) {
        case 'starter':
          if (name !== `${this.barcodeData.corBarcode}_starterNutritionPlan.pdf` && name !== `${this.barcodeData.stBarcode}_starterNutritionPlan.pdf`) {
            this.valid = {
              val: true,
              index: 0,
              message: 'Incorrect filename',
            }
          }
          break
        case 'microbiome':
          if (name !== `${this.barcodeData.corBarcode}_gutMicrobiomeReport.pdf` && name !== `${this.barcodeData.stBarcode}_gutMicrobiomeReport.pdf`) {
            this.valid = {
              val: true,
              index: 1,
              message: 'Incorrect filename',
            }
          }
          break
        case 'restorer':
          if (name !== `${this.barcodeData.corBarcode}_gutRestorerPlan.pdf` && name !== `${this.barcodeData.stBarcode}_gutRestorerPlan.pdf`) {
            this.valid = {
              val: true,
              index: 2,
              message: 'Incorrect filename',
            }
          }
          break
      }
    },
    submit() {
      if (this.starterReportFile === null && this.microbiomeReportFile === null && this.restorerReportFile === null) {
        this.$bvToast.toast('Please upload reports', {
          title: 'No reports found',
          variant: 'primary',
          solid: true,
        })
      } else {
        this.showLoading()
        if (this.valid.val) {
          this.closeLoading()
          return
        }
        this.loading = 0
        if (this.starterReportFile) {
          this.loading += 1
          this.$http
            .post('/barcodes/covid/upload', {
              reportFileName: this.starterReportFile.name,
            })
            .then(data => {
              this.s3upload(data, this.starterReportFile)
            })
            .catch(this.handleError)
        }
        if (this.microbiomeReportFile) {
          this.loading += 1
          this.$http
            .post('/barcodes/covid/upload', {
              reportFileName: this.microbiomeReportFile.name,
            })
            .then(data => {
              this.s3upload(data, this.microbiomeReportFile)
            })
            .catch(this.handleError)
        }
        if (this.restorerReportFile) {
          this.loading += 1
          this.$http
            .post('/barcodes/covid/upload', {
              reportFileName: this.restorerReportFile.name,
            })
            .then(data => {
              this.s3upload(data, this.restorerReportFile)
            })
            .catch(this.handleError)
        }
      }
    },
    s3upload(resp, file) {
      const formData = new FormData()
      // formData.append('Content-Type', 'application/pdf')
      // ! ==============================
      // ! Add file to the form data in the end else it won't work
      // ! ==============================
      formData.append('file', file)
      this.$http
        .put(resp.data, formData, { headers: { 'Content-Type': 'application/pdf' } })
        .then(() => {
          this.$http
            .post('/barcodes/covid/upload/success', { reportFileName: file.name })
            .then(
              this.$bvToast.toast(`${file.name} upload complete`, {
                title: 'Task Complete',
                variant: 'success',
                solid: true,
              }),
            )
            .catch(this.handleError)
        })
        .catch(this.handleError).finally(() => {
          this.loading -= 1
          if (this.loading === 0) {
            this.loading = null
            this.closeLoading()
            this.$router.push(`/barcode/covid/${this.id}/report/view`)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr 1fr;
}
</style>
<style lang="scss">

.modal .modal-content {
  height: 90vh;
}
</style>
